html,
body {
	margin: 0;
}

html {
	background: #000;
	color: #fff;
	font-family: "Titillium Web", sans-serif;
	font-weight: 300;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.anim {
	width: 100%;
	opacity: 0;
	&--to-show {
		animation: fadeIn 1s ease-out both;
	}
	&--to-hide {
		animation: fadeOut 1s ease-out both;
	}
}

.anim-text-container {
	margin: 0 0.25rem;
}

.anim-text {
	opacity: 0;
	animation: fadeIn ease-out both;
}

.letter-glitch {
	font-size: 1rem;
	line-height: 1rem;
	font-family: "DM Mono", monospace;
	display: inline-block;
	content: " ";
	width: 0.6rem;
	height: 1rem;
	text-align: center;
	cursor: pointer;
}

.intro {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 90vw;
	width: 600px;
	display: inline-block;
	transform: translate(-50%, -50%);
	z-index: 1000;

	&__row {
		margin: 0.5rem 0;
	}
}

@keyframes blink {
	50% {
		opacity: 0;
	}
}

.blink {
	animation: blink 0.5s infinite step-start;
}

.app {
	&__header {
		position: fixed;
		top: 0;
		width: 100%;
		padding: 1rem;
		background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

		&__title {
			font-size: 2rem;
			line-height: 1;
			font-weight: bold;
		}
	}
	&__section {
		display: flex;
		> * {
			flex: 1;
		}
	}
	&__background {
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		//pointer-events: none;
		z-index: -1;

		&__renderer {
			width: 100%;
			height: 100%;
			//aspect-ratio: 16/9;
			z-index: 0;
		}
	}

	&__start {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		height: 2rem;
		overflow: hidden;
		cursor: pointer;
	}
}

.url-preview {
	display: inline-block;
	width: 400px;
	color: inherit;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	font-size: 0.8rem;

	&__image {
		width: 100%;
	}

	&__section {
		padding: 1rem;
		display: flex;
		flex-direction: column;
	}

	&__title {
		font-size: 1.1rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0.25rem 0;
	}

	&__description {
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__footer {
		color: #888;
		margin-top: 0.125rem;
		display: flex;
		align-items: center;
		gap: 0.2rem;

		&__icon {
			height: 1.2rem;
		}

		&__date {
			flex: 1;
			text-align: right;
		}
	}
}
