html, body {
  margin: 0;
}

html {
  color: #fff;
  background: #000;
  font-family: Titillium Web, sans-serif;
  font-weight: 300;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.anim {
  opacity: 0;
  width: 100%;
}

.anim--to-show {
  animation: 1s ease-out both fadeIn;
}

.anim--to-hide {
  animation: 1s ease-out both fadeOut;
}

.anim-text-container {
  margin: 0 .25rem;
}

.anim-text {
  opacity: 0;
  animation: ease-out both fadeIn;
}

.letter-glitch {
  content: " ";
  text-align: center;
  cursor: pointer;
  width: .6rem;
  height: 1rem;
  font-family: DM Mono, monospace;
  font-size: 1rem;
  line-height: 1rem;
  display: inline-block;
}

.intro {
  z-index: 1000;
  width: 600px;
  max-width: 90vw;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.intro__row {
  margin: .5rem 0;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: .5s step-start infinite blink;
}

.app__header {
  background: linear-gradient(#000, #0000);
  width: 100%;
  padding: 1rem;
  position: fixed;
  top: 0;
}

.app__header__title {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
}

.app__section {
  display: flex;
}

.app__section > * {
  flex: 1;
}

.app__background {
  z-index: -1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.app__background__renderer {
  z-index: 0;
  width: 100%;
  height: 100%;
}

.app__start {
  text-align: center;
  cursor: pointer;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.url-preview {
  color: inherit;
  flex-direction: column;
  width: 400px;
  font-size: .8rem;
  text-decoration: none;
  display: flex;
}

.url-preview__image {
  width: 100%;
}

.url-preview__section {
  flex-direction: column;
  padding: 1rem;
  display: flex;
}

.url-preview__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: .25rem 0;
  font-size: 1.1rem;
  overflow: hidden;
}

.url-preview__description {
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.url-preview__footer {
  color: #888;
  align-items: center;
  gap: .2rem;
  margin-top: .125rem;
  display: flex;
}

.url-preview__footer__icon {
  height: 1.2rem;
}

.url-preview__footer__date {
  text-align: right;
  flex: 1;
}
/*# sourceMappingURL=index.f5cc1097.css.map */
